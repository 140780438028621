function obj(_obj) {
  function exists(key) {
    return key in _obj;
  }

  function isUndefinedOrNull() {
    return _obj == null;
  }

  return {
    exists,
    isUndefinedOrNull,
  };
}

export default obj;
