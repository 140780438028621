import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query fbInstantGames {
      facebookInstantGames {
        appId
        appSecret
        gameSlug
        namespace
        pageAccessToken
        pageId
        dataSourcePropertyId
        webhookVerifyToken
        marketingAccountId
      }
    }
  `;

  const res = await client.query({ query });
  const fbInstantGames = res.data.facebookInstantGames.map((fbInstantGame) => ({
    ...fbInstantGame,
    marketingAccountId: (fbInstantGame.marketingAccountId || []).map((accountId) => ({
      accountId,
    })),
    id: fbInstantGame.gameSlug,
  }));
  const { total, page } = clientPagination({ list: fbInstantGames, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query fbInstantGame($gameSlug: ID!) {
      facebookInstantGame(gameSlug: $gameSlug) {
        appId
        appSecret
        gameSlug
        namespace
        pageAccessToken
        pageId
        dataSourcePropertyId
        webhookVerifyToken
        marketingAccountId
      }
    }
  `;

  const res = await client.query({ query, variables: { gameSlug: id } });
  const { facebookInstantGame: fbInstantGame } = res.data;
  return {
    data: {
      ...fbInstantGame,
      marketingAccountId: (fbInstantGame.marketingAccountId || []).map((accountId) => ({
        accountId,
      })),
      id: fbInstantGame.gameSlug,
    },
  };
}

async function update({ id, data }) {
  const query = gql`
    mutation updateFbInstantGame(
      $appId: String!
      $appSecret: String
      $gameSlug: ID!
      $namespace: String
      $pageAccessToken: String
      $pageId: String
      $dataSourcePropertyId: String
      $webhookVerifyToken: String
      $marketingAccountId: [String!]
    ) {
      updateFacebookInstantGame(
        gameSlug: $gameSlug
        facebookInstantGameData: {
          appId: $appId
          appSecret: $appSecret
          namespace: $namespace
          pageAccessToken: $pageAccessToken
          pageId: $pageId
          dataSourcePropertyId: $dataSourcePropertyId
          webhookVerifyToken: $webhookVerifyToken
          marketingAccountId: $marketingAccountId
        }
      ) {
        appId
        appSecret
        gameSlug
        namespace
        pageAccessToken
        pageId
        dataSourcePropertyId
        webhookVerifyToken
        marketingAccountId
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      appId: data.appId,
      appSecret: data.appSecret,
      gameSlug: id,
      namespace: data.namespace,
      pageAccessToken: data.pageAccessToken,
      pageId: data.pageId,
      dataSourcePropertyId: data.dataSourcePropertyId,
      webhookVerifyToken: data.webhookVerifyToken,
      marketingAccountId: (data.marketingAccountId || []).map(({ accountId }) => accountId),
    },
  });
  const { updateFacebookInstantGame: updateFbInstantGame } = res.data;
  return {
    data: {
      ...updateFbInstantGame,
      marketingAccountId: (updateFbInstantGame.marketingAccountId || []).map((accountId) => ({
        accountId,
      })),
      id: updateFbInstantGame.gameSlug,
    },
  };
}

async function create({ data }) {
  const query = gql`
    mutation createFbInstantGames(
      $appId: String!
      $appSecret: String
      $gameSlug: ID!
      $namespace: String
      $pageAccessToken: String
      $pageId: String
      $dataSourcePropertyId: String
      $webhookVerifyToken: String
      $marketingAccountId: [String!]
    ) {
      createFacebookInstantGame(
        facebookInstantGameData: {
          appId: $appId
          appSecret: $appSecret
          gameSlug: $gameSlug
          namespace: $namespace
          pageAccessToken: $pageAccessToken
          pageId: $pageId
          dataSourcePropertyId: $dataSourcePropertyId
          webhookVerifyToken: $webhookVerifyToken
          marketingAccountId: $marketingAccountId
        }
      ) {
        appId
        appSecret
        gameSlug
        namespace
        pageAccessToken
        pageId
        dataSourcePropertyId
        webhookVerifyToken
        marketingAccountId
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: {
      appId: data.appId,
      appSecret: data.appSecret,
      gameSlug: data.gameSlug,
      namespace: data.namespace,
      pageAccessToken: data.pageAccessToken,
      pageId: data.pageId,
      dataSourcePropertyId: data.dataSourcePropertyId,
      webhookVerifyToken: data.webhookVerifyToken,
      marketingAccountId: (data.marketingAccountId || []).map(({ accountId }) => accountId),
    },
  });
  const { createFacebookInstantGame: createFbInstantGame } = res.data;
  return {
    data: {
      ...createFbInstantGame,
      marketingAccountId: (createFbInstantGame.marketingAccountId || []).map((accountId) => ({
        accountId,
      })),
      id: createFbInstantGame.gameSlug,
    },
  };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteFBInstanceGame($gameSlug: ID!) {
      deleteFacebookInstantGame(gameSlug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, _delete as delete, deleteMany };
