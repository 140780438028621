import Enumerable from 'linq';

import { obj } from '../utils';

const sortOrderMethodName = (order) => (order === 'ASC' ? 'orderBy' : 'orderByDescending');

/**
 * { pagination: { page: {int} , perPage: {int} }, sort: { field: {string}, order: {string} }, filter: {Object} }
 */
function pagination({ list, options }) {
  if (obj(options).isUndefinedOrNull()) return { total: list.length, page: list };

  const {
    sort: { field: sortBy, order: sortOrder },
    pagination: { page, perPage },
  } = options;
  const currentPage = Enumerable.from(list)
    [sortOrderMethodName(sortOrder)]((item) => item[sortBy])
    .skip((page - 1) * perPage)
    .take(perPage)
    .toArray();
  return { total: list.length, page: currentPage };
}

export default pagination;
