import { gql } from '@apollo/client';

import client from '../graphql-client';
import clientPagination from '../clientSidePagination';

async function getList(options) {
  const query = gql`
    query games {
      games {
        slug
        name
        botService {
          enabled
        }
        gameBackend {
          enabled
        }
        facebookInstantGame {
          appId
        }
        splitTest {
          enabled
        }
        gamebot {
          enabled
        }
        deltaDna {
          projectId
        }
        adMob {
          appId
        }
        appLovin {
          appId
        }
        unity {
          appId
        }
      }
    }
  `;

  const res = await client.query({ query });
  const games = res.data.games.map((game) => ({
    ...game,
    id: game.slug,
    enabledBotService: game.botService?.enabled,
    enabledGameBackend: game.gameBackend?.enabled,
    fbInstantGameAppId: game.facebookInstantGame?.appId,
    enabledSplitTest: game.splitTest?.enabled,
    enabledGamebot: game.gamebot?.enabled,
    deltaDnaProjectId: game.deltaDna?.projectId,
    adMobAppId: game.adMob?.appId,
    appLovinAppId: game.appLovin?.appId,
    unityAppId: game.unity?.appId,
  }));
  const { total, page } = clientPagination({ list: games, options });
  return { data: page, total };
}

async function getOne({ id }) {
  const query = gql`
    query game($slug: ID!) {
      game(slug: $slug) {
        name
        slug
        botService {
          enabled
        }
        gameBackend {
          enabled
        }
        facebookInstantGame {
          appId
        }
        splitTest {
          enabled
        }
        gamebot {
          enabled
        }
        deltaDna {
          projectId
        }
        adMob {
          appId
        }
        appLovin {
          appId
        }
        unity {
          appId
        }
      }
    }
  `;

  const res = await client.query({ query, variables: { slug: id } });
  const { game } = res.data;
  return {
    data: {
      ...game,
      id: game.slug,
      enabledBotService: game.botService?.enabled,
      enabledGameBackend: game.gameBackend?.enabled,
      fbInstantGameAppId: game.facebookInstantGame?.appId,
      enabledSplitTest: game.splitTest?.enabled,
      enabledGamebot: game.gamebot?.enabled,
      deltaDnaProjectId: game.deltaDna?.projectId,
      adMobAppId: game.adMob?.appId,
      appLovinAppId: game.appLovin?.appId,
      unityAppId: game.unity?.appId,
    },
  };
}

async function update({ id, data }) {
  const query = gql`
    mutation updateGame($slug: ID!, $name: String!) {
      updateGame(slug: $slug, gameData: { name: $name }) {
        name
        slug
      }
    }
  `;

  const res = await client.mutate({ mutation: query, variables: { slug: id, name: data.name } });
  const { updateGame } = res.data;
  return { data: { ...updateGame, id: updateGame.slug } };
}

async function create({ data }) {
  const query = gql`
    mutation createGame($slug: ID!, $name: String!) {
      createGame(gameData: { name: $name, slug: $slug }) {
        name
        slug
      }
    }
  `;

  const res = await client.mutate({
    mutation: query,
    variables: { slug: data.slug, name: data.name },
  });
  const { createGame } = res.data;
  return { data: { ...createGame, id: createGame.slug } };
}

async function getMany({ ids }) {
  const { data } = await getList();
  const filteredGames = data.filter((game) => ids.includes(game.id));
  return { data: filteredGames };
}

async function _delete({ id, previousData }) {
  const query = gql`
    mutation deleteGame($gameSlug: ID!) {
      deleteGame(slug: $gameSlug)
    }
  `;

  await client.mutate({
    mutation: query,
    variables: {
      gameSlug: id,
    },
  });

  return { data: previousData };
}

async function deleteMany({ ids }) {
  const pDeletes = ids.map((id) => _delete({ id }));
  await Promise.all(pDeletes);
  return { data: ids };
}

export { getList, getOne, update, create, getMany, _delete as delete, deleteMany };
